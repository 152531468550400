<template>
  <div class="services">
    <h1>Services</h1>
    <div class="block" id="integrative-healthcare-coaching">
      <h2 class="title">💖 Integrative Healthcare Coaching</h2>
      <p>
        Integrative Healthcare is viewing an illness or symptom as a result of
        contributing factors that often have to do with inflammation, gut
        health, pathogen presence, stress levels, toxin levels, sleep-quality,
        emotional distress, and nutrient deficiencies.
      </p>
      <p>
        Imbalances of the body show up as symptoms, and a combination of
        symptoms are labeled as a disease so that doctors can bill health
        insurance and use pharmaceutical interventions. Unfortunately, these
        pharmaceutical interventions often simply mask the symptoms at best, or
        come with a list of side effects at worst. Ultimately, they do not
        address the root causes creating the imbalance. As more time passes and
        root cause imbalances remain unaddressed, more symptoms and disease will
        manifest.
      </p>
      <p>
        The truth is that 95% of diseases are not congenital, they result over
        time due to a combination of some or all of the aforementioned
        contributing factors. Viewing each individual as a distinct case with a
        unique combination and timeline of said factors, and rebalancing the
        compromised parts of the body one-by-one, is what allows the body to
        heal itself. After all, bodies have been healing themselves for
        thousands of years without pharmaceutical intervention, and so too can
        they today with the right approach.
      </p>
      <p>
        As an integrative health practitioner I do not treat or diagnose
        disease, instead I use integrative healthcare modalities to address the
        root causes of symptoms. This includes specialized protocols to address
        the body as a whole as well as its individual systems. My protocols
        include individualized treatments using functional lab testing, herbs,
        vitamins, supplements, nutrition, PNS resetting techniques, and
        lifestyle adjustments that address the imbalances of the body and create
        a safer environment in which the body rebalances itself.
      </p>
      <p>
        Integrative Health Practitioners are alternative & complementary health
        practitioners that use an effective combination the following modalities
        to help their clients address the root causes to their symptoms:
      </p>
      <img
        class="modality-img"
        src="../assets/ihp-diagram.png"
        alt="modalities diagram"
      />
    </div>

    <div class="block" id="bio-individual-personal-training">
      <h2 class="title">🏋🏽‍♀️ Bio-Individual Personal Training</h2>
      <p>
        At our fully equipped studio in Okotoks, Alberta, you will receive
        specialized personal training to address your aesthetic goals, pain,
        mobility, muscle imbalances, core strength, and more.
      </p>
      <p>
        Over the years I have gained invaluable experience working with a
        variety of clients. I have had experience working with children in
        specialized cases such as scoliosis degree reduction, as well as with
        the elderly to rehabilitate knees, hips, and overall strength after
        surgery or injury. I also use my specialized muscle activation
        techniques to grow, sculpt, and shape specific muscles according to my
        clients desires. I am a certified pre & post natal trainer and have
        helped many women rehabilitate diastasis recti and their pelvic floors.
      </p>
      <p>
        My training style is complementary to my integrative health coaching
        services as I am well-versed in specialized exercise design &
        application for individuals dealing with chronic illness, hormonal
        imbalance, and pain.
      </p>
      <p>Take a look at some of my clients’ results below!</p>
      <div class="img-grid">
        <img src="../assets/before-after.png" alt="before after image" />
        <img src="../assets/before-after01.png" alt="before after image 1" />
        <img src="../assets/before-after02.png" alt="before after image 2" />
        <img src="../assets/before-after03.png" alt="before after image 3" />
        <img src="../assets/before-after04.png" alt="before after image 4" />
        <img src="../assets/before-after05.png" alt="before after image 5" />
        <img src="../assets/before-after06.png" alt="before after image 6" />
        <img src="../assets/before-after07.png" alt="before after image 7" />
      </div>
    </div>

    <div class="block" id="reiki">
      <h2 class="title">🌀 Reiki & Sound Therapy</h2>
      <p>
        Reiki is a simple modality that uses universal life energy to encourage
        profound healing. It does not just act on the physical body, it also
        acts on the emotional and energetic bodies to promote positive feelings
        and emotional & physical healing. Reiki is an excellent complementary
        modality to assist in your pursuit of health, vitality, and wellness. At
        our studio in Okotoks, Alberta you will find yourself in a comfortable,
        supportive, and positive environment reaping the rewards of reiki &
        sound healing in just one session. To work on more deeply embedded
        traumas and blockages, multiple sessions are required.
      </p>
      <p>Some of the benefits of reiki therapy include:</p>
      <ul>
        <li>
          Removes energy blockages that create imbalances between the mind,
          body, & spirit
        </li>
        <li>
          Rebalances your energy centers and brings healing to their associated
          organs
        </li>
        <li>Promotes emotional & energetic healing</li>
        <li>Increase energy levels</li>
        <li>
          Allows the body to get into a restorative PNS state, promoting deep
          relaxation and the release of conscious and unconscious tension
        </li>
        <li>Improves sleep</li>
        <li>Increases energy levels</li>
        <li>Reduces blood pressure</li>
        <li>Relieves physical pain</li>
        <li>Supports the immune system</li>
        <li>Helps spiritual growth and emotional cleansing</li>
        <li>Compliments medical treatments</li>
      </ul>
    </div>

    <div class="block" id="bio-individual-exercise-programs">
      <h2 class="title">🏃🏼‍♂️ Bio-Individual Exercise Programs</h2>
      <p>
        If you want to lose weight, minimize your waistline, sculpt your body,
        improve your strength, reduce your pain, or just improve your overall
        health, bio-individualized exercise programs are available online.
        Included in your program is a virtual movement assessment to ensure
        program design is tailor-made to your body. Bio-individual exercise
        programs complement root cause health protocols perfectly by
        accelerating healing, reducing overall stress, and building a healthy
        lifestyle.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Services",
  components: {},
};
</script>

<style scoped>
.block {
  margin-bottom: 100px;
  border: 3px solid var(--color-purple);
  border-radius: 20px;
  padding: 40px;
}

h1 {
  background: var(--main-gradient);
  text-align: center;
  border-radius: 20px;
  padding: 10px;
  color: white;
  text-transform: uppercase;
  margin-bottom: 40px;
}

p {
  margin-bottom: 20px;
}

img {
  width: 100%;
}

.modality-img {
  max-width: 700px;
  margin: 0 auto;
  display: block;
}

.img-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

@media (max-width: 750px) {
  .img-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 500px) {
  .img-grid {
    grid-template-columns: 1fr;
  }
}
</style>
